import { useClientTranslation } from "@rimo/i18n/useClientTranslation";
import React from "react";
import styled from "styled-components";
import { RimoVoiceGenericLPProps } from "./RimoVoiceGenericLP";
import { MOBILE_BREAKPOINT } from "./shared";

const POINT_LIST_DATA = {
  writing: [
    {
      image_url: "/assets/about_genelic/writing/point1",
      head: ["1時間の取材を5分で文字起こし", "高い正確性で、確実な成果へ"],
      text: "高速・高精度な文字起こしで時間と手間を節約し、重要なタスクに集中できます。ミスや誤解を防ぎ、あなたのアウトプットをより完璧なものに。",
    },
    {
      image_url: "/assets/about_genelic/writing/point2",
      head: ["AIテンプレートで", "スムーズな執筆を"],
      text: "取材・インタビューから記事の下書きを自動生成。時間と手間がかかる作業はすべてAIにお任せ。あなたのライティングをよりクリエイティブに",
    },
    {
      image_url: "/assets/about_genelic/writing/point3",
      head: ["Zoom･GoogleMeet対応", "オンライン取材も簡単取り込み"],
      text: "代表的なオンライン通話サービスである、Zoom、Google Meet、Microsoft Teams の全てに対応。録画ボタンをクリックしておくだけで、取材をすぐにrimoで見られます。",
    },
  ],

  proceedings: [
    {
      image_url: "/assets/about_genelic/proceedings/point1",
      head: ["動画＋文章で", "会議を高精度に記録"],
      text: "会議後すぐに、日本語に特化したAIによる、自然な日本語の文字起こしが完成。AIが発言者を識別して自動で発言を分離し、読みやすく段落分けされた文章にまとまります。",
    },
    {
      image_url: "/assets/about_genelic/proceedings/point2",
      head: ["AI が議事録を作成", "内容の振り返りも時短"],
      text: "ChatGPT を利用して文字起こしの要約を手軽に生成。長くなった文字起こしを全て読まなくてもすぐに全体を把握できます。",
    },
    {
      image_url: "/assets/about_genelic/proceedings/point3",
      head: ["多くのオンライン会議アプリと連携"],
      text: "代表的な会議ツールである、Zoom、Microsoft Teams、Google Meet の全てに対応。一度設定しておけば、これからの会議すべてを文字に記録できます。",
    },
  ],

  training: [
    {
      image_url: "/assets/about_genelic/training/point1",
      head: ["前後の文章を見れることで", "高速視聴が可能"],
      text: "動画を視聴しながら、前後の文章を見ることでより深いインプットへ。2倍・3倍速での高速視聴ができることで、1人1人の受講者の研修時間を効率化できます。",
    },
    {
      image_url: "/assets/about_genelic/training/point2",
      head: ["ポイントをハイライト、", "短時間で要点がわかる"],
      text: "選択箇所からかんたんにサマリーを作成。クリックして元の発言や動画まで辿れるから、見たいところへすぐにアクセス。情報共有の認識ズレもおこりづらくなります。",
    },
    {
      image_url: "/assets/about_genelic/training/point3",
      head: ["多くのオンライン会議アプリと連携"],
      text: "代表的な会議ツールである、Zoom、Microsoft Teams、Google Meet の全てに対応。一度設定しておけば、これからの研修すべてを文字に記録できます。",
    },
  ],

  businessAnalysis: [
    {
      image_url: "/assets/about_genelic/businessAnalysis/point1",
      head: ["1時間の商談を5分で文字に", "高い正確性で確実な成果へ"],
      text: "商談後すぐに、日本語に特化したAIによる、自然な日本語の文字起こしが完成。AIが発言者を識別して自動で発言を分離し、読みやすく段落分けされた文章にまとまります。",
    },
    {
      image_url: "/assets/about_genelic/businessAnalysis/point2",
      head: ["商談のまとめ自動生成で", "振り返りを加速"],
      text: "商談録画をもとに、AIが自動でまとめを作成します。生成されたまとめは手動で編集したり、AIと対話しながらブラッシュアップも可能。より手軽に、質の高いレポートを作成できます。",
    },
    {
      image_url: "/assets/about_genelic/businessAnalysis/point3",
      head: ["オンライン会議ツールと", "連携で簡単取り込み"],
      text: "代表的なミーティングツールである、Zoom、Microsoft Teams、Google Meet の全てに対応。録画ボタンをクリックしておくだけで、商談をまるごと記録します。",
    },
  ],

  userResearch: [
    {
      image_url: "/assets/about_genelic/userResearch/point1",
      head: ["書記はすべておまかせ", "インタビューに専念できます"],
      text: "記録に気を取られて、ユーザーの行動や表情を見逃してしまう事はもうありません。録画・録音・文字起こしはすべて任せてインタビューに集中しましょう。自分の気づきなど録画に残らない重要なメモは、リアルタイム文字起こしでタイムスタンプ付きで記録できます。",
    },
    {
      image_url: "/assets/about_genelic/userResearch/point2",
      head: ["AIを使ったレポート作成も", "これ一つで完結"],
      text: "質疑応答の形式でAIがインタビューのまとめを自動生成。生成されたまとめを手動で編集したり、AIと対話しながらブラッシュアップも可能。より手軽に、質の高いレポートを作成できます。",
    },
    {
      image_url: "/assets/about_genelic/userResearch/point3",
      head: ["管理・共有も簡単", "チームでのデータ活用を加速"],
      text: "インタビュー内容はRimoで一括管理できます。チームごとのフォルダ分け(法人プランのみ)やタグ付けで簡単整理。簡単共有でチーム内全体でのインタビュー活用を強力に後押しします。",
    },
  ],
  transcription: [
    {
      image_url: "/assets/about_genelic/transcription/feature1",
      head: ["日本語に特化したAIで", "自動で文字起こし"],
      text: "会議で録った音声ファイル、イベントの動画ファイルをアップロードすると、日本語に特化した自然言語処理技術で音声を文字に変換します。マイク機能でその場での録音も可能。",
    },
    {
      image_url: "/assets/about_genelic/transcription/feature2",
      head: ["テキストと音声が", "シンクするスライダー機能"],
      text: "音声データとテキスト（文字起こしした文章）がシンクしているので、文字を選択することでピンポイントに該当部分の音声を聞き返すことが可能です。長時間録音したものでも、聞きたいところだけを簡単に引き出せます。",
    },
    {
      image_url: "/assets/about_genelic/transcription/feature5",
      head: ["AI が要約を作成", "内容の振り返りも時短"],
      text: "ChatGPT を利用して文字起こしの要約を手軽に生成。長くなった文字起こしを全て読まなくてもすぐに全体を把握できます。",
    },
    {
      image_url: "/assets/about_genelic/transcription/feature3",
      head: ["文字起こしデータのシェア"],
      text: "会議の参加者全体にシェアすることで、議事録の代わりにしていただけます。打ち合わせ内容など、口頭で話した内容を後でカンタンに確認することが可能です。文字起こし結果や音声データのダウンロードも可能です。",
    },
  ],
};
export const PointList: React.FC<Pick<RimoVoiceGenericLPProps, "target">> = (props) => {
  const { customT } = useClientTranslation();
  return (
    <Content>
      {POINT_LIST_DATA[props.target].map((item) => (
        <PointItem key={item.image_url}>
          <HeadMb>
            {item.head.map((i) => (
              <div key={i}>{customT(i)}</div>
            ))}
          </HeadMb>
          <ImgPC src={`${item.image_url}-pc.png`} />
          <ImgMobileWrapper>
            <ImgMobile src={`${item.image_url}-mb.png`} />
          </ImgMobileWrapper>
          <TextBlock>
            <HeadPC>
              {item.head.map((i) => (
                <div key={i}>{customT(i)}</div>
              ))}
            </HeadPC>
            <Description>{customT(item.text)}</Description>
          </TextBlock>
        </PointItem>
      ))}
    </Content>
  );
};

const Content = styled.div`
  @media (min-width: ${`${MOBILE_BREAKPOINT + 1}px`}) {
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT}px`}) {
    margin-top: 24px;
  }
`;
const ImgPC = styled.img`
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    width: 50%;
    max-height: 460px;
    object-fit: contain;
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    display: none;
  }
`;
const ImgMobileWrapper = styled.div`
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    display: none;
  }
  width: 100%;
  max-width: 680px;
  margin: 0 auto;
`;
const ImgMobile = styled.img`
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    width: 100%;
  }
`;
const TextBlock = styled.div`
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    width: calc(50% - 24px);
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    padding: 0 0 30px;
  }
`;
const Head = styled.h2`
  font-weight: 700;
  color: #222222;
`;
const HeadMb = styled(Head)`
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    display: none;
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    font-size: 27px;
    line-height: 40px;
    margin-bottom: 8px;
  }
`;
const HeadPC = styled(Head)`
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    margin-bottom: 12px;
    font-size: 40px;
    line-height: 56px;
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    display: none;
  }
`;
const Description = styled.p`
  margin-top: 16px;
  font-weight: 400;
  color: #222222;
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    font-size: 18px;
    line-height: 32px;
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    font-size: 15px;
    line-height: 24px;
  }
`;
const PointItem = styled.div`
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 56px 0 0;
    :nth-child(2n) {
      flex-direction: row-reverse;
    }
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    padding: 26px 0;
  }
`;
