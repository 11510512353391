import { useClientTranslation } from "@rimo/i18n/useClientTranslation";
import React from "react";
import styled, { keyframes } from "styled-components";
import { RimoVoiceGenericLPProps } from "./RimoVoiceGenericLP";
import { MOBILE_BREAKPOINT, SlimContent, X_LARGE_BREAKPOINT } from "./shared";
import { PrimaryButton, StyledSecondaryButton } from "../../components/landing/voice/Button";
import { Trans } from "react-i18next/TransWithoutContext";
import Link from "next/link";
import { latoBaseStr } from "@rimo/ui-old";

export const CONTENTS_DATA = {
  proceedings: {
    head: "<span>AI文字起こし</span>で<br />まるごと<span>議事録</span>",
    description:
      "会議のすべてを、高速・高精度文字起こしと動画でありのままに記録し、要点は手軽にサマライズ。場の空気感まで共有できます。",
    buttonText: "資料ダウンロード",
    buttonLink: undefined,
    hash: "contact-form",
    bottomText: "1時間の会議が、５分で文字に",
    isPersonal: false,
  },
  writing: {
    head: "<span>取材した音声</span>が<br />その場で<span>文字に</span>",
    description:
      "取材・インタビューを高速・高精度に文字起こし。さらに要約の自動生成も。時間と手間がかかる作業はすべてAIにお任せ。あなたのライティングをよりクリエイティブに。",
    buttonText: "無料トライアル",
    buttonLink: "/about",
    hash: undefined,
    bottomText: "音声・動画記録からの<br />文字起こしは、AIの仕事に",
    isPersonal: true,
  },
  training: {
    head: "<span>社内研修の動画</span>をかんたんに<span>シェア</span>",
    description:
      "社内外で行った研修を、高速・高精度文字起こしと動画でありのままに記録し、要点は手軽にサマライズ。場の空気感まで共有できます。",
    buttonText: "資料ダウンロード・無料相談",
    buttonLink: undefined,
    hash: "contact-form",
    bottomText: "社内に共有する動画を<br />1ヶ所に蓄積",
    isPersonal: false,
  },
  businessAnalysis: {
    head: "<span>商談録画</span>が<br />その場で<span>文字に</span>",
    description:
      "商談の録画を高速・高精度に文字起こし。さらに要約の自動生成も。時間と手間のかかる作業はすべてAIにおまかせ。文字起こしから商談分析まで、これひとつで。",
    buttonText: "無料トライアル",
    buttonLink: undefined,
    hash: "contact-form",
    bottomText: "音声・動画記録からの<br />文字起こしは、AIの仕事に",
    isPersonal: false,
  },
  userResearch: {
    head: "<span>インタビュー</span>が<br />その場で<span>文字に</span>",
    description:
      "取材・インタビューを高速・高精度に文字起こし。さらに要約の自動生成も。時間と手間のかかる作業はすべてAIにおまかせ。あなたにしかできないタスクに集中できます。",
    buttonText: "無料トライアル",
    buttonLink: "/about",
    hash: undefined,
    bottomText: "音声・動画記録からの<br />文字起こしは、AIの仕事に",
    isPersonal: true,
  },
  transcription: {
    head: "<span>AI</span>で音声･動画を<br /><span>文字起こし</span>",
    description:
      "1時間の音声データを約5分で文字起こし。打ち合わせやオンラインイベントなど様々な場面で、手軽にご活用いただけます。",
    buttonText: "無料トライアル",
    buttonLink: "/about",
    hash: undefined,
    bottomText: "音声・動画記録からの<br />文字起こしは、AIの仕事に",
    isPersonal: false,
  },
};
export const Top: React.FC<Pick<RimoVoiceGenericLPProps, "target">> = (props) => {
  const { customT } = useClientTranslation();
  const contentsData = CONTENTS_DATA[props.target];
  const logosTop = (
    <>
      <LogoWrapper>
        <LogoImg src="/assets/about_genelic/client/logo1.png" />
      </LogoWrapper>
      <LogoWrapper>
        <LogoImg src="/assets/about_genelic/client/logo2.jpg" />
      </LogoWrapper>
      <LogoWrapper>
        <LogoImg src="/assets/about_genelic/client/logo11.jpg" />
      </LogoWrapper>
      <LogoWrapper>
        <LogoImg src="/assets/about_genelic/client/logo3.jpg" />
      </LogoWrapper>
      <LogoWrapper>
        <LogoImg src="/assets/about_genelic/client/logo4.png" />
      </LogoWrapper>
      <LogoWrapper>
        <LogoImg src="/assets/about_genelic/client/logo6.jpg" />
      </LogoWrapper>
      <LogoWrapper>
        <LogoImg src="/assets/about_genelic/client/logo7.png" />
      </LogoWrapper>
      <LogoWrapper>
        <LogoImg src="/assets/about_genelic/client/logo17.png" />
      </LogoWrapper>
      <LogoWrapper>
        <LogoImg src="/assets/about_genelic/client/logo12.jpg" />
      </LogoWrapper>
    </>
  );

  const logosBottom = (
    <>
      <LogoWrapper>
        <LogoImg src="/assets/about_genelic/client/logo8.jpg" />
      </LogoWrapper>
      <LogoWrapper>
        <LogoImg src="/assets/about_genelic/client/logo9.jpg" />
      </LogoWrapper>
      <LogoWrapper>
        <LogoImg src="/assets/about_genelic/client/logo10.png" />
      </LogoWrapper>
      <LogoWrapper>
        <LogoImg src="/assets/about_genelic/client/logo5.png" />
      </LogoWrapper>
      <LogoWrapper>
        <LogoImg src="/assets/about_genelic/client/logo13.webp" />
      </LogoWrapper>
      <LogoWrapper>
        <LogoImg src="/assets/about_genelic/client/logo14.png" />
      </LogoWrapper>
      <LogoWrapper>
        <LogoImg src="/assets/about_genelic/client/logo15.jpg" />
      </LogoWrapper>
      <LogoWrapper>
        <LogoImg src="/assets/about_genelic/client/logo16.png" />
      </LogoWrapper>
    </>
  );

  return (
    <Content target={props.target}>
      <Main>
        <LeftBlock>
          <Head>
            <Trans i18nKey={contentsData.head} components={{ span: <span /> }} />
          </Head>
          <Description>{customT(contentsData.description)}</Description>
          <ButtonList>
            <Button link={contentsData.buttonLink} hash={contentsData.hash}>
              {customT(contentsData.buttonText)}
            </Button>
            {contentsData.isPersonal || props.target === "transcription" ? (
              <Link href={{ hash: "contact-form" }}>
                <SecondaryButton>{customT("資料請求する")}</SecondaryButton>
              </Link>
            ) : (
              <a
                target="_blank"
                href="https://app.spirinc.com/external/teams/eIsKvaPHl1e6nv6Nsh19-/availability-sharings/La5VFKfMzAbXh-SWdY-n1/confirm"
                rel="noreferrer"
              >
                <SecondaryButton>{customT("無料トライアル申し込み")}</SecondaryButton>
              </a>
            )}
          </ButtonList>
        </LeftBlock>
        <ImageWrapper>
          <ScreenImages>
            <PCImage src={`/assets/about_genelic/${props.target}/pc-screen.png`} />
            <MobileImage src={`/assets/about_genelic/${props.target}/mobile-screen.png`} />
          </ScreenImages>
          <Logos>
            <LogoImage src="/assets/about_genelic/zoom.png" />
            <LogoImage src="/assets/about_genelic/teams.png" />
            <LogoImage src="/assets/about_genelic/meet.png" />
          </Logos>
        </ImageWrapper>
      </Main>
      <BottomText as="h3">
        <Trans i18nKey={contentsData.bottomText} components={{ span: <span /> }} />
      </BottomText>
      <LogoContainer>
        <Slider>
          {logosTop}
          {logosTop}
          {logosTop}
        </Slider>
        <Slider>
          {logosBottom}
          {logosBottom}
          {logosBottom}
        </Slider>
      </LogoContainer>
    </Content>
  );
};

const Content = styled.div<Pick<RimoVoiceGenericLPProps, "target">>`
  background-repeat: no-repeat;
  @media (min-width: ${`${X_LARGE_BREAKPOINT}px`}) {
    padding-top: 216px;
    background-image: url("/assets/about_genelic/bg-top-pc.png"),
      url(${(p) => `/assets/about_genelic/${p.target}/top-back-pc.jpg`});
    background-size:
      100% 150%,
      cover;
    background-position:
      center top,
      center top;
  }
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) and (max-width: ${`${X_LARGE_BREAKPOINT - 1}px`}) {
    padding-top: 216px;
    background-image: url("/assets/about_genelic/bg-top-pc.png"),
      url(${(p) => `/assets/about_genelic/${p.target}/top-back-pc.jpg`});
    background-size:
      1730px 136%,
      cover;
    background-position:
      -50px top,
      top center;
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    padding: 88px 0 0;
    background-image: url("/assets/about_genelic/bg-top-mobile.png"),
      url(${(p) => `/assets/about_genelic/${p.target}/top-back-mobile.jpg`});
    background-size:
      100% 170%,
      100%;
    background-position:
      center top,
      center top;
  }
`;
const Main = styled(SlimContent)`
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    padding-top: 72px;
    padding-bottom: 78px;
    grid-template-areas: "text image";
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    grid-template-areas:
      "image"
      "text";
  }
  background-position:
    center top,
    center top;
  display: grid;
  gap: 24px;
  align-items: center;
`;
const LeftBlock = styled.div`
  grid-area: text;
`;
const Head = styled.h1`
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    font-size: 72px;
    line-height: 88px;
    letter-spacing: 0.01em;
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    font-size: 42px;
    line-height: 56px;
  }
  span {
    font-weight: 700;
  }
`;
const Description = styled.p`
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    margin-top: 32px;
    font-size: 18px;
    line-height: 32px;
    width: 520px;
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    margin-top: 24px;
    font-size: 15px;
    line-height: 24px;
  }
`;
const ImageWrapper = styled.div`
  grid-area: image;
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    position: relative;
    height: 360px;
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    position: relative;
  }
`;
const ScreenImages = styled.div`
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    position: relative;
    height: 100%;
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
`;
const Logos = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    left: 8px;
    gap: 8px;
    top: -5%;
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    left: 5%;
    top: -18px;
  }
`;
const LogoImage = styled.img`
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    width: 80px;
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    width: 40px;
  }
`;
const PCImage = styled.img`
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    width: 91%;
    position: absolute;
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    width: 58%;
  }
`;
const MobileImage = styled.img`
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    width: 33%;
    position: absolute;
    right: -100px;
    top: 38px;
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    width: 20%;
    margin-left: 10px;
  }
`;
const Button = styled(PrimaryButton)`
  width: fit-content;
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    margin-top: 38px;
    padding: 14px 48px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    width: 100%;
    margin-top: 42px;
  }
`;
const SecondaryButton = styled(StyledSecondaryButton)`
  width: fit-content;
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    margin-top: 38px;
    padding: 14px 48px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    width: 100%;
    margin-top: 42px;
  }
`;
const ButtonList = styled.div`
  display: flex;
  gap: 18px;
  align-items: center;
`;
const BottomText = styled(SlimContent)`
  font-family: ${latoBaseStr};
  text-align: center;
  color: #ff8000;
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    padding-top: 40px;
    padding-bottom: 40px;
    font-size: 32px;
    line-height: 44px;
    letter-spacing: 7.56px;
    br {
      display: none;
    }
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    padding: 36px 12px;
    font-size: 22px;
    line-height: 32px;
  }
`;
const slide = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-33.3%);
  }
`;

const LogoContainer = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media (min-width: ${`${MOBILE_BREAKPOINT + 1}px`}) {
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT}px`}) {
    margin-top: 18px;
  }
`;
const Slider = styled.div`
  display: flex;
  gap: 8px;
  animation: ${slide} 15s linear infinite;
  width: fit-content;
`;
const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;

  @media (min-width: ${`${MOBILE_BREAKPOINT + 1}px`}) {
    height: 50px;
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT}px`}) {
    height: 38px;
  }
`;
const LogoImg = styled.img<{ $height?: number }>`
  display: block;
  height: ${(p) => (p.$height ? `${p.$height}%` : "100%")};
`;
