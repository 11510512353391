"use client";

import React from "react";
import styled from "styled-components";

import { MOBILE_BREAKPOINT, SlimContent } from "./shared";
import { RequestMaterialForm } from "@rimo/frontend/components/landing/voice/RequestMaterialForm";

export const ContactForm: React.FC = () => {
  return (
    <ConversionFormSection id="contact-form">
      <SlimContent>
        <RequestMaterialForm />
      </SlimContent>
    </ConversionFormSection>
  );
};

const ConversionFormSection = styled.section`
  position: relative;
  background-repeat: no-repeat;
  @media (min-width: ${`${MOBILE_BREAKPOINT + 1}px`}) {
    padding: 194px 0 88px;
    background-image: url("/assets/about_genelic/bg-cta-pc.svg");
    background-position: center top;
    background-size: cover;
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT}px`}) {
    padding: 130px 0 80px;
    background-image: url("/assets/about_genelic/bg-cta-mobile.svg");
    background-size: cover;
    background-position: center -90px;
    margin-top: 96px;
  }
`;
