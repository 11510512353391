import { useClientTranslation } from "@rimo/i18n/useClientTranslation";
import React from "react";
import styled from "styled-components";
import Link from "next/link";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";

import { FooterBottom } from "./FooterBottom";
import { MOBILE_BREAKPOINT, SlimContent } from "./shared";
import { LocaleLink } from "../../components/LocaleLink";

export const Footer: React.FC = () => {
  return (
    <>
      <FooterTop />
      <FooterBottom />
    </>
  );
};

// const LINK_LIST = [
//   {
//     head: "ソリューション",
//     links: [
//       {
//         url: "/about/voice_interview",
//         linkHash: undefined,
//         label: "文字起こし",
//       },
//       {
//         url: "/about/voice_proceedings",
//         linkHash: undefined,
//         label: "議事録",
//       },
//     ],
//   },
// ]

const FooterTop: React.FC = () => {
  const { customT } = useClientTranslation();

  return (
    <Wrapper>
      <Content>
        <LeftBlock>
          <ServiceNameSub>{customT("AI文字起こし")}</ServiceNameSub>
          <ServiceName>Rimo voice</ServiceName>
        </LeftBlock>
        <LinkList>
          <LinkItem>
            <Head>{customT("リソース")}</Head>
            <Link href={{ hash: "contact-form" }}>
              <LinkLabel as="span">{customT("資料請求")}</LinkLabel>
            </Link>
            <LocaleLink href={"/about/voice_partners"}>
              <LinkLabel as="span">{customT("販売パートナー")}</LinkLabel>
            </LocaleLink>
            <LinkLabel href="/blogs">{customT("お役立ち情報")}</LinkLabel>
            <LinkLabel href="https://guide.rimo.app" target="_blank">
              {customT("ヘルプ")}
            </LinkLabel>
          </LinkItem>
        </LinkList>
        <RightBlock>
          <Link
            href={{
              hash: "contact-form",
            }}
          >
            <LinkButtonPrimary>
              <MailOutlinedIcon />
              {customT("お問い合わせ")}
              <div />
            </LinkButtonPrimary>
          </Link>
          <LocaleLink
            href={{
              pathname: `/about`,
            }}
          >
            <LinkButtonSecondary>
              <LoginOutlinedIcon />
              {customT("ログイン")}
              <div />
            </LinkButtonSecondary>
          </LocaleLink>
        </RightBlock>
      </Content>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  width: 100%;
  background: #fafafa;
`;
const Content = styled(SlimContent)`
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    display: grid;
    grid-template-columns: 256px 1fr auto;
    gap: 16px;
    padding-top: 64px;
    padding-bottom: 64px;
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    padding-top: 32px;
    padding-bottom: 38px;
  }
`;
const LeftBlock = styled.div``;
const ServiceNameSub = styled.h3`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: rgba(0, 0, 0, 0.56);
`;
const ServiceName = styled.h3`
  font-weight: 700;
  font-size: 29px;
  line-height: 40px;
  letter-spacing: 0.18px;
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    margin-bottom: 24px;
  }
`;
const LinkList = styled.div`
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    display: flex;
  }
`;
const LinkItem = styled.div`
  cursor: pointer;
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    margin-right: 16px;
    width: 200px;
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    margin-top: 12px;
  }
`;
const Head = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    margin-bottom: 16px;
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    margin-bottom: 12px;
  }
`;
const LinkLabel = styled.a`
  display: block;
  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.56);
  margin-top: 8px;
`;
const RightBlock = styled.div`
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    margin-top: 30px;
  }
`;
const ButtonBase = styled.button`
  padding: 10px 16px;
  display: grid;
  grid-template-columns: 16px 1fr 16px;
  margin: 0;
  outline: 0;
  border: none;
  border-radius: 100px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  align-items: center;
  cursor: pointer;
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    width: 230px;
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    width: 100%;
  }
  svg {
    width: 18px;
    height: 18px;
  }
`;
const LinkButtonPrimary = styled(ButtonBase)`
  background: rgba(255, 175, 0, 0.24);
  color: #f14c00;
`;
const LinkButtonSecondary = styled(ButtonBase)`
  margin-top: 12px;
  background: #fafafa;
`;
