import {
  User,
  getRedirectResult,
  getAuth,
  getAdditionalUserInfo,
  AdditionalUserInfo,
  IdTokenResult,
} from "firebase/auth";

import { createFirebaseAuthenticateErrorMessage } from "./error";
import { onSignIn, onSignUp } from "./event";
import { Team } from "@rimo/frontend/models";
import Sentry from "@rimo/frontend/utils/sentry";

export type UserExtraInfo = { user: User; extra: AdditionalUserInfo | null; idToken: IdTokenResult };
export type UserExtraInfoAuthResult = {
  userExtra: UserExtraInfo;
  /** ユーザー認証種類 */
  kind: "signUp" | "signIn" | "reload" | "custom";
  /** ユーザー認証後のリダイレクト先 */
  redirectTo?: string | undefined;
  /** (今は) 既存ユーザーが企業からの招待を自動承認したかどうか */
  acceptedOrganization?: Team | undefined;
};

export type GetRedirectResultCallback = (user: User, kind: "signUp" | "signIn") => Promise<void>;

/**
 * ソーシャルログイン(リダイレクト)の結果を取得し、サインアップ・ログインをどちらも対応する
 * ページのリロード時は呼び出されない
 * @param forceRefresh トークンを強制的にリフレッシュさせるかどうか
 * @returns Promise<UserExtraInfoAuthResult | null>
 */
export async function getRedirectSignedInUser(
  callbacks: Partial<{
    onSignUp: GetRedirectResultCallback;
    onSignIn: GetRedirectResultCallback;
  }>
): Promise<UserExtraInfoAuthResult | null> {
  try {
    const credential = await getRedirectResult(getAuth());

    if (!credential) {
      // console.debug(
      //   "[Firebase Auth getRedirectResult()] credential not found, user maybe has aborted signIn or reload browser"
      // );
      return null;
    }

    const { user } = credential;
    const userInfo = getAdditionalUserInfo(credential);

    if (userInfo && userInfo.isNewUser) {
      // console.debug("[Firebase Auth getRedirectResult] signUp", credential);

      // SAMLログインの時、displayNameがデフォルトで入らないらしいので、属性値から取るようにする
      const samlAttrName = userInfo?.profile?.displayName;
      const shouldUpdateName = !user.displayName && Boolean(samlAttrName) && typeof samlAttrName === "string";
      const userName = shouldUpdateName ? samlAttrName : undefined;
      const userExtraInfo = await onSignUp(credential, { userName });
      await callbacks.onSignUp?.(user, "signUp");

      return userExtraInfo;
    } else {
      // console.debug("[Firebase Auth getRedirectResult] signIn", credential);
      const userExtraInfo = await onSignIn(credential);
      await callbacks.onSignIn?.(user, "signIn");

      return userExtraInfo;
    }
  } catch (err) {
    const defaultError = "リダイレクト結果からユーザー情報を取得中にエラーが発生しました。";
    const [error, isFirebaseError] = createFirebaseAuthenticateErrorMessage(err, defaultError);
    if (!isFirebaseError) Sentry.captureException(err);
    console.warn("[Firebase Auth getRedirectResult]", err);
    throw error;
  }
}
