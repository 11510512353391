import { useClientTranslation } from "@rimo/i18n/useClientTranslation";
import { PrimaryButton } from "@rimo/frontend/components/landing/voice/Button";
import { latoBaseStr } from "@rimo/ui-old";
import React from "react";
import styled from "styled-components";

import { RimoVoiceGenericLPProps } from "./RimoVoiceGenericLP";
import { MOBILE_BREAKPOINT } from "./shared";

const TEXT = {
  writing: {
    head: "60分無料トライアルで文字起こしをAIにおまかせ",
    description:
      "時間と手間を削減し、創作に没頭しましょう。高度な自動化技術が、効率的な取材とスムーズな編集を実現します。",
    button: "無料トライアル",
    buttonLink: "/about",
    hash: "",
  },
  proceedings: {
    head: "失敗しないDX。リモートワークも改善。",
    description:
      "Rimo導入で議事録作成にかかっていた時間は確実に削減。すぐに成果の見えるDXを達成できます。情報伝達・ナレッジ共有の改善で、リモートワークの課題も解決へ。",
    button: "無料で相談",
    buttonLink: undefined,
    hash: "contact-form",
  },
  training: {
    head: "これからの研修はオンライン・非同期で効率化",
    description:
      "コンテンツを再利用してメンバーが見たいときにオンラインで何度も見れるようにすることで全体の時間を削減。情報伝達・ナレッジ共有の改善で、リモートワークの課題も解決へ。",

    button: "無料で相談",
    buttonLink: undefined,
    hash: "contact-form",
  },
  businessAnalysis: {
    head: "いますぐ、文字起こし作業をAIにおまかせ",
    description:
      "高度な自動化技術で時間と手間を削減し、効率的に成果をだしましょう。さらに情報伝達・ナレッジ共有の改善でビジネスをさらに加速させます。",
    button: "無料で相談",
    buttonLink: undefined,
    hash: "contact-form",
  },
  userResearch: {
    head: "記録からまとめ作成まで、これひとつで",
    description: "録画・録音・文字起こしはすべておまかせ。コストを削減してより効率的で効果的なユーザーリサーチを。",
    button: "無料トライアル",
    buttonLink: "/about",
    hash: "",
  },
  transcription: {
    head: "今すぐ、文字起こし作業はAIに切り替えましょう",
    description: "30秒22円でできるカンタン自動文字起こし。スマホとパソコンでご利用頂けます",
    button: "無料トライアル",
    buttonLink: "/about",
    hash: "",
  },
};
export const CTABlock: React.FC<Pick<RimoVoiceGenericLPProps, "target">> = (props) => {
  const { customT } = useClientTranslation();
  const data = TEXT[props.target];
  return (
    <Content>
      <LeftBlock>
        <Head>{customT(data.head)}</Head>
        <Description>{customT(data.description)}</Description>
      </LeftBlock>
      <RightBlock>
        <Button hash={data.hash} link={data.buttonLink}>
          {customT(data.button)}
        </Button>
      </RightBlock>
    </Content>
  );
};
const Content = styled.div`
  width: 100%;
  box-sizing: border-box;
  color: white;
  border-radius: 16px;
  box-shadow:
    0px 8px 16px rgba(0, 0, 0, 0.1),
    0px 0px 0px rgba(0, 0, 0, 0.02);
  background-size: cover, cover;
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    margin-top: 72px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.03), rgba(0, 0, 0, 0.03)),
      linear-gradient(269.96deg, rgba(255, 175, 0, 0.64) 8.69%, rgba(241, 76, 0, 0.64) 91.89%),
      url("/assets/about_genelic/bg-attention-pc.svg");
    padding: 72px 120px;
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    margin: 32px 0;
    padding: 32px 16px 30px;
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.03), rgba(0, 0, 0, 0.03)),
      linear-gradient(269.96deg, rgba(255, 175, 0, 0.64) 8.69%, rgba(241, 76, 0, 0.64) 91.89%),
      url("/assets/about_genelic/bg-attention-mobile.png");
  }
`;
const LeftBlock = styled.div`
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    margin-right: 18px;
  }
`;
const Head = styled.h1`
  font-weight: 700;
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    font-size: 40px;
    line-height: 56px;
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    font-size: 28px;
    line-height: 40px;
  }
`;
const Description = styled.p`
  font-family: ${latoBaseStr};
  margin-top: 16px;
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    font-size: 18px;
    line-height: 32px;
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    font-size: 15px;
    line-height: 24px;
  }
`;
const RightBlock = styled.div`
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    flex-shrink: 0;
  }
`;
const Button = styled(PrimaryButton)`
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    flex-shrink: 0;
    padding: 12px 44px;
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    margin-top: 22px;
    width: 144px;
  }
`;
