import { useClientTranslation } from "@rimo/i18n/useClientTranslation";
import { useLocaleContext } from "@rimo/frontend/contexts/LocaleProvider";
import React from "react";
import Link from "next/link";
import { useSearchParamsString } from "@rimo/frontend/hooks/useNavigation";
import styled from "styled-components";

import { MOBILE_BREAKPOINT, SlimContent } from "./shared";
import { LocaleLink } from "../../components/LocaleLink";
import { fallbackLng } from "@rimo/i18n/settings";

export const FooterBottom: React.FC = () => {
  const { customT } = useClientTranslation();
  const { locale } = useLocaleContext();
  const searchParamsString = useSearchParamsString();

  return (
    <Content>
      <FooterWrapper>
        <FooterLeftBlock>
          <img src="/assets/rimo_logo.svg" alt="Rimo logo" height="22" />
        </FooterLeftBlock>
        <FooterRightBlock>
          <LocaleLink href={`/about/company${searchParamsString}`} passHref legacyBehavior>
            <FooterItem>{customT("運営会社")}</FooterItem>
          </LocaleLink>
          <Link
            href={
              locale === fallbackLng
                ? `/policies/terms${searchParamsString}`
                : `/global_policies/terms${searchParamsString}`
            }
            passHref
            legacyBehavior
          >
            <FooterItem>{customT("利用規約")}</FooterItem>
          </Link>
          <Link
            href={
              locale === fallbackLng
                ? `/policies/privacy${searchParamsString}`
                : `/global_policies/privacy${searchParamsString}`
            }
            passHref
            legacyBehavior
          >
            <FooterItem>{customT("プライバシーポリシー")}</FooterItem>
          </Link>
          <LocaleLink href={`/policies/security${searchParamsString}`} passHref legacyBehavior>
            <FooterItem>{customT("情報セキュリティ方針")}</FooterItem>
          </LocaleLink>
          <LocaleLink href={`/policies/scta${searchParamsString}`} passHref legacyBehavior>
            <FooterItem>{customT("特定商取引法に基づく表記")}</FooterItem>
          </LocaleLink>
          <FooterCopyRight>© 2020 RIMO LLC.</FooterCopyRight>
        </FooterRightBlock>
      </FooterWrapper>
    </Content>
  );
};

const Content = styled.div``;
const FooterWrapper = styled(SlimContent)`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    padding-top: 44px;
    padding-bottom: 44px;
    align-items: center;
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    padding: 32px 16px;
    flex-direction: column;
  }
`;
const FooterLeftBlock = styled.div`
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    margin-bottom: 28px;
  }
`;
const FooterRightBlock = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
const FooterItem = styled.a`
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.56);
  @media (min-width: ${`${MOBILE_BREAKPOINT}px`}) {
    margin-right: 32px;
  }
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;
const FooterCopyRight = styled.div`
  color: #888888;
  font-size: 12px;
  @media (max-width: ${`${MOBILE_BREAKPOINT - 1}px`}) {
    margin-top: 24px;
  }
`;
